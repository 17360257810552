import React from "react";

import logoAzul from "../../assets/images/logos/logo-radir-azul.svg";
import style from "./styles.module.scss";

export const AboutComponent = () => {
  return (
    <div className={style.cAbout} id="sobre-a-empresa">
      <div className={style.cAboutContent}>
        <h2>Quem Somos</h2>
        <div className={style.cAboutDescription}>
          <div className={style.cAboutImg}>
            <img src={logoAzul} alt="[Logo RADIR]" />
          </div>
          <div className={style.cAboutInfo}>
            <h3>Um pouco sobre nós.</h3>
            <p>
              Somos a <span>RADIR Tecnologia Em Sistemas</span>, uma empresa
              especializada em sistemas de segurança eletrônica, redes de dados
              e automação. Possuímos uma equipe qualificada, com experiência no
              ramo de atuação, que zela por um atendimento personalizado para
              empresas e residências.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
