import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import style from "./styles.module.scss";

export const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    fetch("https://radirreact.centralweb.com.br/email.php", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data.message); // Exibe a mensagem de resposta
        // Limpa o formulário se o e-mail for enviado com sucesso
        if (data.message === "E-mail enviado com sucesso!") {
          setFormData({
            name: "",
            email: "",
            subject: "",
            message: "",
          });
          toast.success(data.message);
        } else {
          toast.info(data.message);
        }
      })
      .catch((error) => {
        toast.error('Erro ao enviar a mensagem.');
      });
  };

  return (
    <form onSubmit={handleSubmit} className={style.form}>
      <div>
        <input
          placeholder="Nome"
          type="text"
          id="name"
          name="name"
          value={formData.name}
          onChange={handleInputChange}
        />
      </div>
      <div>
        <input
          placeholder="E-mail"
          type="email"
          id="email"
          name="email"
          value={formData.email}
          onChange={handleInputChange}
        />
      </div>
      <div>
        <input
          placeholder="Assunto"
          type="text"
          id="subject"
          name="subject"
          value={formData.subject}
          onChange={handleInputChange}
        />
      </div>
      <div>
        <textarea
          placeholder="Mensagem"
          id="message"
          name="message"
          value={formData.message}
          onChange={handleInputChange}
          maxLength={800}
        ></textarea>
      </div>
      <button type="submit" className="cBtn btnBlue">
        Enviar
      </button>
    </form>
  );
};
