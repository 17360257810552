import React from "react";
import style from "./styles.module.scss";
import { CardComponent } from "../Card";

import acesso from "../../assets/images/services/acesso.webp";
import callcenter from "../../assets/images/services/callcenter.webp";
import cameras from "../../assets/images/services/cameras.webp";
import cercaEletrica from "../../assets/images/services/cerca-eletrica.webp";
import cercaVirtual from "../../assets/images/services/cerca-virtual.webp";
import cftv from "../../assets/images/services/cftv.webp";
import manutencao from "../../assets/images/services/manutencao.webp";
import redes from "../../assets/images/services/redes.webp";
import telefonia from "../../assets/images/services/telefonia.webp";

export const WorksComponent = () => {
  const servicos = [
    {
      id: 1,
      title: "CONTROLES DE ACESSO E ALARMES",
      description:
        "Em segurança, especialmente segurança física, o termo controle de acesso é uma referência à prática de permitir o acesso a uma propriedade, apenas para pessoas autorizadas.",
      btn: "SABER MAIS",
      img: acesso,
    },
    {
      id: 2,
      title: "SISTEMAS PARA CALL CENTERS",
      description:
        "Melhore a experiência do cliente com soluções de central de atendimento.",
      btn: "SABER MAIS",
      img: callcenter,
    },
    {
      id: 3,
      title: "SISTEMAS DE IMAGENS",
      description: "Monitoramento de imagem: solução para diversos setores.",
      btn: "SABER MAIS",
      img: cameras,
    },
    {
      id: 4,
      title: "SISTEMAS DE CERCA ELÉTRICA",
      description:
        "Uma cerca eletrificada é uma barreira que usa o choque elétrico para impedir que animais ou pessoas de atravessarem um limite.",
      btn: "SABER MAIS",
      img: cercaEletrica,
    },
    {
      id: 5,
      title: "SISTEMAS DE CERCA VIRTUAL",
      description:
        "Sistema de barreiras de luz infra-vermelho invisível com altura variável de 70 a 170 cm. Forma uma grade de feixe invisível que ao ser transposta aciona o sistema de alarme.",
      btn: "SABER MAIS",
      img: cercaVirtual,
    },
    {
      id: 6,
      title: "SISTEMAS DE CFTV",
      description:
        "Circuito fechado ou circuito interno de televisão é um sistema de televisão que distribui sinais provenientes de câmeras localizadas em locais específicos, para um ou mais pontos de visualização.",
      btn: "SABER MAIS",
      img: cftv,
    },
    {
      id: 7,
      title: "MANUTENÇÃO ESPECIALIZADA EM EQUIPAMENTOS ELETRICOS ELETRÔNICOS",
      description:
        "Manutenção geral de portões eletrônicos, motores elétricos e rede elétrica predial.",
      btn: "SABER MAIS",
      img: manutencao,
    },
    {
      id: 8,
      title: "MANUTENÇÃO DDE REDES DIGITAIS E ANALÓGICAS",
      description:
        "Com a implementação, o cabeamento estruturado é flexibilizado para suportar as modificações.",
      btn: "SABER MAIS",
      img: redes,
    },
    {
      id: 9,
      title: "SISTEMAS DE TELEFONIA DIGITAL E ANALÓGICA",
      description:
        "A solução de telefonia IP completa, trazendo uma robusta central telefônica no local ou em nuvem.",
      btn: "SABER MAIS",
      img: telefonia,
    },
  ];

  return (
    <div className={style.cWork} id="servicos">
      <div className={style.cWorkContent}>
        <h2>Nossos Serviços</h2>
        <div className={style.cWorkDescription}>
          {servicos.map((v) => (
            <CardComponent data={v} key={v.id} />
          ))}
        </div>
      </div>
    </div>
  );
};
