import React from "react";

import style from "./styles.module.scss";
export const FooterComponent = () => {
  return (
    <footer className={style.footer}>
      <div>
        <p>Termos de Uso</p>
        <p>Privacidade</p>
        <p>Política de Cookies</p>
      </div>
      <p>
        Todos os direitos reservados para RADIR&#174; Tecnologia em Sistemas -{" "}
        {new Date().getFullYear()}
      </p>
      <p>
        Desenvolvido por <a>Central Web</a>
      </p>
    </footer>
  );
};
