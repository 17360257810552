import { ToastContainer } from "react-toastify";
import { HomePage } from "./pages/HomePage";

import 'react-toastify/dist/ReactToastify.css';

function App() {
  return (
    <>
      <ToastContainer position="top-right" autoClose={3000} />
      <HomePage />
    </>
  );
}

export default App;
