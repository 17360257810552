import React from "react";
import Iframe from "react-iframe";
import { FaFacebook, FaInstagram, FaWhatsapp } from "react-icons/fa";
import { ContactForm } from "../Forms";
import style from "./styles.module.scss";

export const ContactComponent = () => {
  return (
    <div className={style.cClient} id="contato">
      <div className={style.cClientContent}>
        <h2>Fale Conosco</h2>
        <p>
          Ficou com alguma dúvida? Gostaria de fazer um orçamento? Preencha o
          formulário abaixo que o responderemos o mais breve possível ou acesse
          um dos nossos canais e poste sua duvida! Teremos o maior prazer em
          atende-lo!
        </p>
        <div className={style.cClientDescription}>
          <div className={style.formContact}>
            <ContactForm />
          </div>
          <div className={style.contactInfo}>
            <div className={style.contactLocalization}>
              <p>
                <span>Endereço:</span> Rua Matias Velho, 116, Canoas/RS.
              </p>
              <p>
                <span>E-mail:</span>contato@radir.com.br
              </p>
              <p>
                <span>Telefone:</span> (51) 9 9951.2005
              </p>
            </div>
            <div className={style.social}>
              <a>
                <FaFacebook />
              </a>
              <a>
                <FaInstagram />
              </a>
              <a>
                <FaWhatsapp />
              </a>
            </div>
            <div className={style.map}>
              <Iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3458.354065416257!2d-51.1854859249711!3d-29.911707425132796!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95197aabc7b313a5%3A0x7737ff6442fc1217!2sR.%20Mathias%20Velho%2C%20116%20-%20Centro%2C%20Canoas%20-%20RS%2C%2092310-000!5e0!3m2!1spt-BR!2sbr!4v1684867759910!5m2!1spt-BR!2sbr"
                width="100%"
                height="100%"
                styles={{ border: "0" }}
                allowfullscreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              ></Iframe>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
