import React from "react";
import { FaAlignJustify } from 'react-icons/fa'

import style from "./styles.module.scss";

export const NavComponent = () => {
  return (
    <>
      <div className={style.btnHeader}>
        <FaAlignJustify />
      </div>
      <nav className={style.nav}>
        <ul>
          <li>
            <a href="#home">
              <span>Home</span>
            </a>
          </li>
          <li>
            <a href="#sobre-a-empresa">
              <span>Sobre a Empresa</span>
            </a>
          </li>
          <li>
            <a href="#servicos">
              <span>Serviços</span>
            </a>
          </li>
          <li>
            <a href="#clientes">
              <span>Clientes</span>
            </a>
          </li>
          <li>
            <a href="#contato">
              <span>Contato</span>
            </a>
          </li>
        </ul>
      </nav>
    </>
  );
};
