import React from 'react';
import style from './styles.module.scss'
import { HeaderComponent } from '../../components/Header';
import { AboutComponent } from '../../components/About';
import { WorksComponent } from '../../components/Works';
import { ClientsComponent } from '../../components/Clients';
import { ContactComponent } from '../../components/Contact';
import { FooterComponent } from '../../components/Footer';

export const HomePage = () => {
    return (
        <div className={style.cContainer}>
            <HeaderComponent />
            <AboutComponent />
            <WorksComponent/>
            <ClientsComponent/>
            <ContactComponent />
            <FooterComponent/>
        </div>
    );
}
