import React, { useEffect, useState } from "react";
import style from "./style.module.scss";

export const CardComponent = (props) => {

  return (
    <div className={style.cCard}>
      <img src={props.data.img} alt={props.data.title} />
      <div className={style.content}>
        <div className={style.contentDesc}>
          <h2>{props.data.title}</h2>
          <p>{props.data.description}</p>
        </div>
        <div className={style.btn}>
          <button className="cBtn btnBlue">{props.data.btn}</button>
        </div>
      </div>
    </div>
  );
};
