import React from "react";
import { NavComponent } from "./Nav";
import logoBranco from '../../assets/images/logos/logo-radir-branco.svg'

import style from "./styles.module.scss";
export const HeaderComponent = () => {
  return (
    <div className={style.cHeader}>
      <div className={style.cHeaderContent}>
        <div className={style.cHeaderItem1}>
          <img
            src={logoBranco}
            alt="[Logo Radir]"
          />
          <NavComponent />
        </div>
        <div className={style.cTitle}>
          <h2>Soluções com excelencia e respeito ao cliente.</h2>
        </div>
      </div>
    </div>
  );
};

