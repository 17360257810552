import React from "react";
import style from "./styles.module.scss";

import maeDeDeus from '../../assets/images/logos/mae-de-deus.svg'
import verdesCampos from '../../assets/images/logos/verdes-campos.svg'
import baush from '../../assets/images/logos/bausch-lomb.svg'
import extreme from '../../assets/images/logos/extreme.svg'

export const ClientsComponent = () => {
  return (
    <div className={style.cClient} id="clientes">
      <div className={style.cClientContent}>
        <h2>Principais Clientes</h2>
        <div className={style.cClientDescription}>
            <img src={maeDeDeus} alt="Cliente Mãe de Deus" />
            <img src={verdesCampos} alt="Cliente Verdes Campos" />
            <img src={baush} alt="Cliente Baush Lomb" />
            <img src={extreme} alt="Cliente Extreme" />          
        </div>
      </div>
    </div>
  );
};
